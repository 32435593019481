<template>
  <div>
    <div v-if="showAdminTable" class="all-apartments">
      <ApartmentsTable title="All Apartments" :list="apartmentList" :fields="fields" :loading="loading"
                  :availabilityButton="true" :manageAvailabilityButton="true" @update-list="update"
                  @edit="editApartment" @delete="deleteApartment" @restore="restoreApartment"
                  @show-availability="manageAvailabilityTables(false,false)"
                  @manage-availability="manageAvailabilityTables(false,true)"/>
      <CalendarModal :periods="periodsSelected"/>
    </div>
    <div v-else>
      <AdminAvailabilityTable title="All Apartments Availability"
                              :list="apartmentList" :loading="loading" :unitsButton="true" :manageAvailability="manageAvailability" :isApartment="true"
                              @update-list="update" @show-units="manageAvailabilityTables(true,false)"/>
    </div>
  </div>
</template>

<script>
import AdminAvailabilityTable from '@/components/admin/AdminAvailabilityTable'
import CalendarModal from '@/components/admin/calendar/CalendarModal'
import {apartmentService} from '@/services/admin'
import ApartmentsTable from "@/components/admin/ApartmentsTable.vue";
import {mapState} from "vuex";

export default {
  name: 'AdminAllApartments',
  components: {
    ApartmentsTable,
    AdminAvailabilityTable,
    CalendarModal,
  },
  data() {
    return {
      apartmentList: [],
      filteredApartmentList: [],
      auxList: [],
      loading: false,
      periodsSelected: [],
      showAdminTable: true,
      //RF-87
      adminAvailabilityTableTitle: "All Apartments Availability",
      manageAvailability: false
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Apartment' },
        { key: 'type.name', label: 'Type' },
        { key: 'addressCity', label: 'City' },
        { key: 'addressStreet', label: 'Address' },
        { key: 'addressNumber', label: 'Number' },
        { key: 'addressZipCode', label: 'Zip Code'},
        { key: 'numOfPerson', label: 'Num Person'},
        { key: 'floor', label: 'Floor' },
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['edit', 'delete'] },
      ]
    },
    ...mapState({
      apartment: state => state.adminApartmentStore.apartment
    })
  },
  async beforeMount() {
    await this.update()
  },
  methods: {
    // openCalendar(apartment) {
    //   this.$store.commit('adminApartmentStore/setApartment', apartment)
    //   this.periodsSelected = apartment.periods;
    //   this.$bvModal.show('calendar-modal');
    // },
    async update() {
      this.loading = true
      this.filteredApartmentList = []
      this.apartmentList = []
      this.auxList = await apartmentService.getApartments()
      this.auxList.forEach(apartment => {
        this.apartmentList.push(apartment)
        this.filteredApartmentList.push(apartment)
      });
      this.loading = false
    },
    editApartment(apartment) {
      this.$store.commit('adminApartmentStore/setApartment', apartment)
      this.$store.commit('adminBuildingStore/setBuilding', null)
      this.$router.push({ name: 'apartment-edit', query: { id: apartment.id } })
    },
    async restoreApartment(apartment) {
      await apartmentService.restoreApartment(apartment.id)
      await this.update()
    },
    async deleteApartment(apartment) {
      this.$store.commit('adminApartmentStore/setApartment', null)
      const response = await apartmentService.deleteApartment(apartment.id)
      await this.update()
    },
    //RF-87
    manageAvailabilityTables(showOriginalTable,manageAvailability){
      this.showAdminTable = showOriginalTable
      if(!this.showAdminTable){
        this.manageAvailability = manageAvailability
        if(manageAvailability){
          this.adminAvailabilityTableTitle = 'Manage Apartments Availability'
        }
        else{
          this.adminAvailabilityTableTitle = 'All Apartments Availability'
        }
      }
      else{
        this.adminAvailabilityTableTitle = 'All Apartments Availability'
        this.manageAvailability = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
