<template>
  <div class="admin-table">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h1 class="admin-title mb-0">{{ $t(title) }}</h1>
      <div class="d-flex">
        <RFButton label="Update" size="small" background="#24425B" @on-click="$emit('update-list')"/>
        <RFButton v-if="availabilityButton" label="Show availability" size="small" background="#24425B"
                  @on-click="$emit('show-availability')"/>
        <RFButton v-if="manageAvailabilityButton" label="Manage availability" size="small" background="#24425B"
                  @on-click="$emit('manage-availability')"/>
      </div>
    </div>

    <div class="admin-table-filters" v-if="list.length > 0">
      <ValidationObserver ref="validator">
        <form @submit.prevent>
          <b-row>
            <b-col>
              <RFValidation name="Apartment name">
                <RFInput label="Apartment name" v-model="filter.apartmentName"/>
              </RFValidation>
            </b-col>
          </b-row>
          <b-row class="justify-content-end">
            <b-col/>
            <b-col/>
            <b-col class="text-center">
              <RFButton class="mt-3" label="Clear" background="#24425B" v-if="!loading" @on-click="clearFilters"/>
              <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
            </b-col>
            <b-col class="text-center">
              <RFButton class="mt-3" label="Search" background="#24425B" v-if="!loading" @on-click="search"/>
              <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
            </b-col>
            <b-col/>
            <b-col/>
          </b-row>
        </form>
      </ValidationObserver>
    </div>

    <b-table class="mt-5" responsive hover sticky-header :items="list" :fields="fields" :per-page="perPage"
             :filter="filterToSearch" :filter-function="filterFunction" @filtered="onFiltered"
             :current-page="currentPage" v-show="resultList.length && !loading" sort-icon-left>
      <template v-slot:head()="data">
        <div class="table-label">
          <template v-if="data.field.flag">
            <flag :squared="false" :iso="data.field.flag"/>
          </template>
          {{ data.label }}
        </div>
      </template>

      <template v-slot:cell()="data">
        <div class="table-data">
          <template v-if="data.field.key === 'status'">
            <div class="status">{{ data.value | replaceUnderscore | toUpperCase }}
            </div>
          </template>
          <template v-else-if="data.field.type === 'boolean'">
            <FontAwesomeIcon :icon="['fas', 'check']" :color="'#5CB85C'" v-if="data.value"/>
          </template>
          <template v-else-if="data.field.type === 'actions'">
            <div class="d-inline" v-for="(action, index) in data.field.actions" :key="`action-${index}`">
              <FontAwesomeIcon v-if="action === 'edit'"
                               class="cursor-pointer mx-1" :icon="['fas', 'pen']" @click="$emit('edit', data.item)"
                               data-toggle="tooltip" data-placement="top" title="Edit"/>
              <template v-else-if="action === 'delete'">

                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'trash']" color="#FF5A5F"
                                 @click="selectItem(data.item)"
                                 v-if="((selectedItem && selectedItem.id !== data.item.id) || !confirm) && !data.item.deleted"
                                 data-toggle="tooltip" data-placement="top" title="Delete"/>
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'trash-restore']" color="#24425B"
                                 @click="selectItem(data.item)"
                                 v-if="data.item.deleted && !confirm"
                                 data-toggle="tooltip" data-placement="top" title="Restore"/>
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'check']" color="#5CB85C"
                                 @click="data.item.deleted ? restoreItem() : deleteItem()"
                                 v-if="confirm && (selectedItem && selectedItem.id === data.item.id)"
                                 data-toggle="tooltip" data-placement="top" title="Confirm"/>
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'times']" color="#FF5A5F"
                                 @click="confirm = false"
                                 v-if="confirm && (selectedItem && selectedItem.id === data.item.id)"
                                 data-toggle="tooltip" data-placement="top" title="Cancel"/>
              </template>
            </div>
          </template>
          <template v-else>{{ data.value }}</template>
        </div>
      </template>
    </b-table>
    <b-pagination
      class="mt-4"
      v-if="!loading && resultList.length > perPage"
      v-model="currentPage"
      :total-rows="resultList.length"
      :per-page="perPage"/>
    <div class="admin-loading" v-if="loading">
      <RFLoader class="mr-3" color="#24425B" :size="30"/>
      {{ $t('Uploading...') }}
    </div>
    <div class="empty-list" v-if="!loading && !resultList.length">
      {{ $t('There are no items.') }}
    </div>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import {formatDate, replaceUnderscore, toUpperCase} from '@/helpers/filters'

import RFInput from "@/components/forms/RFInput.vue";
import RFValidation from "@/components/forms/RFValidation.vue";
import {mapState} from "vuex";

export default {
  name: 'ApartmentsTable',
  components: {
    RFValidation, RFInput,
    RFButton,
    RFLoader,
  },
  props: {
    list: Array,
    fields: Array,
    title: String,
    loading: Boolean,
    perPage: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    availabilityButton: {
      type: Boolean,
      default: false,
    },
    //RF-87
    manageAvailabilityButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItem: null,
      confirm: false,
      filter: {
        apartmentName: null,
      },
      filterToSearch: null,
      resultList: this.list,
    }
  },
  computed: {
    ...mapState({
      filterData: state => state.adminApartmentStore.filterData
    }),
  },
  beforeMount() {
    this.resultList = this.list
    if(this.filterData!=null){
      this.filter = Object.assign({}, this.filterData.filter);
      this.currentPage = this.filterData.currentPage
      this.search()
    }
  },
  methods: {
    onFiltered(filteredItems) {
      let newCurrentPage = 1
      if(this.filterData!=null){
        newCurrentPage = this.filterData.currentPage
        this.$store.commit('adminApartmentStore/setFilterData', null)
      }
      this.currentPage = newCurrentPage
      this.resultList = filteredItems
    },
    selectItem(item) {
      this.selectedItem = item
      this.confirm = true
    },
    deleteItem() {
      this.confirm = false
      this.$emit('delete', this.selectedItem)
    },
    restoreItem() {
      this.confirm = false
      this.$emit('restore', this.selectedItem)
    },
    search(){
      this.filterToSearch = Object.assign({}, this.filter);
    },
    filterFunction(row, filter) {
      return filter.apartmentName == null || (row.name !== null && row.name.toLowerCase().includes(filter.apartmentName.toLowerCase()))
    },
    clearFilters(){
      Object.keys(this.filter).forEach(key=>{
        this.filter[key] = null
      })
      this.search()
    },
  },
  filters: {
    formatDate,
    replaceUnderscore,
    toUpperCase
  }
}
</script>

<style lang="scss" scoped>


.admin-table {
  .table-label {
    @include font-style($montserrat-bold, 'medium', $font-12);
  }
}
</style>

