<template>
  <RFModal title="Calendar" :modal-id="modalId" hide-footer @shown="focusMonthPickerInput">
    <RFMonthPicker label="Dates" :occuped-periods="this.periods" disabled ref="calendarModalMonthPicker"/>
  </RFModal>
</template>

<script>
import RFModal from '@/components/modals/RFModal'
import RFMonthPicker from '@/components/forms/RFMonthPicker'

export default {
  name: 'CalendarModal',
  components: {
    RFModal,
    RFMonthPicker,
  },
  props:{
    periods: Array
  },
  data() {
    return {
      modalId: 'calendar-modal'
    }
  },
  methods:{
    focusMonthPickerInput(){
      this.$refs.calendarModalMonthPicker.$children[0].$el.children[0].focus();
    }
  }
}
</script>

<style>

</style>
